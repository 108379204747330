import { memo, useCallback } from "react";
import PropTypes from "prop-types";
import { MonthView } from "react-calendar";
import { FormattedDate } from "react-intl";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import { isDate, isSameDay } from "date-fns";
import IconCalendarFlightTakeOff from "app/pages/.shared/static/icons/IconCalendarFlightTakeOff";
import "./CalendarMonth.scss";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const CalendarMonth = ({
	month,
	selectedDate = [],
	handleDateClick,
	departureDateMax,
	departureDateMin,
	checkDateAvailability = () => {},
	displayCalendarIcon = true,
}) => {
	const monthValue = Number(month.split("/")[0]);
	const yearValue = Number(month.split("/")[1]);

	const activeStartDate = new Date(yearValue, monthValue, 1);

	let departureDateMaxUTC = new Date(departureDateMax);
	departureDateMaxUTC = new Date(
		departureDateMaxUTC.getFullYear(),
		departureDateMaxUTC.getMonth(),
		departureDateMaxUTC.getDate(),
		0,
		0,
		0
	);

	let departureDateMinUTC = new Date(departureDateMin);
	departureDateMinUTC = new Date(
		departureDateMinUTC.getFullYear(),
		departureDateMinUTC.getMonth(),
		departureDateMinUTC.getDate(),
		0,
		0,
		0
	);

	const renderTileContent = useCallback(
		({ date }) => {
			if (selectedDate?.length > 0) {
				const isDepartureDate = isSameDay(date, new Date(selectedDate[0]));
				const isEndDate = isSameDay(date, new Date(selectedDate[1]));

				if (isDepartureDate || isEndDate) {
					return <IconCalendarFlightTakeOff />;
				}
			}

			return null;
		},
		[selectedDate]
	);

	const getTileClassName = useCallback(
		({ date, view }) => {
			if (view === "month") {
				if (
					checkDateAvailability(date) ||
					isBefore(date, departureDateMinUTC) ||
					isAfter(date, departureDateMaxUTC) ||
					(selectedDate && selectedDate[0] && !isDate(selectedDate[0])) || // should not disable depart date
					(selectedDate && selectedDate[1] && !isDate(selectedDate[1])) || // should not disable end date
					(selectedDate[0] &&
						selectedDate[1] &&
						isBefore(date, selectedDate[0]) &&
						isAfter(date, selectedDate[1])) // should not disable dates between depart and end date
				) {
					return "date-picker-calendar-tile__tile date-picker-calendar-tile__tile--disabled";
				}
			}
			return "date-picker-calendar-tile__tile";
		},
		[departureDateMinUTC, departureDateMaxUTC, selectedDate]
	);

	return (
		<div className="datepicker-calendar-month">
			<div>
				<Typography
					variant={TYPOGRAPHY_VARIANTS.REGULAR}
					isBold
					component="p"
					className="datepicker-calendar-month__month"
				>
					<FormattedDate
						timeZone="UTC"
						value={new Date(yearValue, monthValue, 15, 15, 0, 0)} // 15, 15, 0, 0 pour éviter bug sur win7 ie11 décalage mois
						month="long"
						year="numeric"
					/>
				</Typography>
			</div>
			<MonthView
				value={selectedDate}
				activeStartDate={activeStartDate}
				tileClassName={getTileClassName}
				onClick={handleDateClick}
				tileDisabled={({ date }) => {
					return checkDateAvailability(date);
				}}
				{...displayCalendarIcon && { tileContent: renderTileContent }}
			/>
		</div>
	);
};

CalendarMonth.propTypes = {
	month: PropTypes.string,
	selectedDate: PropTypes.array,
	handleDateClick: PropTypes.func,
	departureDateMin: PropTypes.number,
	departureDateMax: PropTypes.number,
	checkDateAvailability: PropTypes.func,
	displayCalendarIcon: PropTypes.bool,
};

export default memo(CalendarMonth);
